$theme-colors: (
  'purple': #6f42c1,
);

@import '~bootstrap/scss/bootstrap';

.ReusableButton__container {
  position: relative;

  &.isButtonDisabled {
    opacity: 0.785;
    cursor: not-allowed;
  }

  .ReusableButton__spinner {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.btn-purple {
    background-color: var(--bs-purple);
  }

  &.btn-outline-light {
    border: 1px solid var(--bs-dark);

    &:hover {
      border: 1px solid var(--bs-dark);
    }
  }
}

.ReusableButton__tooltip {
  .tooltip-inner {
    text-align: left;
  }
}
