.DriverCard__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 4px;
  background-color: white;
  border: 1px solid #e5e5e5;
  position: relative;

  & > :nth-child(1),
  & > :nth-child(2) {
    margin-left: 0;
  }
  & > :last-child {
    margin-left: auto;
  }

  .terminalSection {
    display: flex;
    align-items: center;
    margin-right: 2px;
    .CompanyAvatar__initialName {
      font-size: 12px;
    }
  }
  .driverAvatar {
    display: inline-flex;
    vertical-align: middle;
    cursor: pointer;
    align-items: center;
  }

  .driverFullName {
    color: rgb(102, 102, 102);
    font-size: 14px;
    font-weight: 600;
  }

  .statusBadge {
    display: inline-block;
    vertical-align: middle;
  }

  .closeIcon {
    position: absolute;
    top: -7px;
    right: -6px;
    cursor: pointer;
  }

  .indexBadge {
    position: absolute;
    top: -7px;
    left: -8px;
    width: 16px;
    height: 16px;
    background: var(--ion-color-warning);
    text-align: center;
    color: black;
    border-radius: 20px;
    font-size: 10px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.DriverCard__terminalCreateButton {
  height: auto;
  display: inline-block;
  width: auto;
  vertical-align: middle;
}

.DriverCard__truckSelector {
  &:hover {
    border: none;
    background-color: transparent;
  }

  .ConcordFormDropdownV2__input-container {
    position: absolute;
  }

  .ConcordFormDropdownV2__value-container {
    padding-left: 0 !important;
  }

  .ConcordFormDropdownV2__multi-value {
    display: none;
  }

  &.DropdownWithCustomChildren__container {
    display: inline-block;
    vertical-align: middle;
    width: initial !important;
  }
}

.DriverCard__truckTerminalAvatar {
  .CompanyAvatar__initialName {
    font-size: 12px;
  }
}

.DriverCard__avatar {
  margin-right: 2px;
  .CompanyAvatar__initialName {
    font-size: 12px;
  }
}
