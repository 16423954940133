.rc-tree-switcher {
  display: none !important; /* Completely hide the switcher icon */
}

// .ReusableFolderView__container {
//   padding: 16px;
//   background-color: #f9f9f9;
//   border: 1px solid #ddd;
//   border-radius: 8px;
//   margin: 0 auto;
// }

.rc-tree {
  font-size: 14px;
  color: #333;

  .rc-tree-treenode {
    display: flex;
    align-items: center;
    padding: 8px 12px !important;
    transition: background-color 0.2s ease, color 0.2s ease;
    cursor: pointer;
    position: relative;
    border-left: 2px solid transparent; /* Default transparent border */

    &:hover {
      background-color: #f0f5ff; /* Light blue hover effect */
      color: #1890ff;

      .rc-tree-icon__custom svg {
        fill: #1890ff;
      }
    }

    &-selected {
      background-color: #e6f7ff !important;
      color: #096dd9 !important;
      border: 1px solid #096dd9;

      .rc-tree-icon__custom svg {
        fill: #096dd9;
      }
    }

    &-level-1 {
      border-left: 2px solid #ddd;
    }

    &-level-2 {
      border-left: 2px solid #ccc;
    }

    &-level-3 {
      border-left: 2px solid #bbb;
    }

    &-level-4 {
      border-left: 2px solid #aaa;
    }

    &:nth-child(odd) {
      background-color: #fafafa; /* Light gray for odd rows */
    }

    &:nth-child(even) {
      background-color: #ffffff; /* White for even rows */
    }

    .rc-tree-node-content-wrapper {
      height: auto !important; /* Override the height property */
      line-height: normal; /* Adjust line height for better alignment */
      width: 100%;
    }
  }

  .rc-tree-node-selected {
    background-color: transparent !important;
    box-shadow: none;
  }

  .rc-tree-child-tree {
    margin-left: 16px; /* Indent child nodes */
    border-left: 2px solid #ddd; /* Add a vertical line for hierarchy */
    padding-left: 8px;

    .rc-tree-treenode {
      border-left: 2px solid #ddd;
      padding-left: 12px;
    }
  }

  .rc-tree-icon__custom {
    margin-right: 8px;
    display: flex;
    align-items: center;

    svg {
      width: 16px;
      height: 16px;
      fill: #8c8c8c;
      transition: fill 0.2s ease;
    }
  }

  .rc-tree-icon__folder {
    margin-right: 8px;
    color: #faad14; /* Folder color */

    &:hover {
      color: #1890ff; /* Hover color for icons */
    }
  }

  .rc-tree-icon__file {
    margin-right: 8px;
    color: #8c8c8c; /* File color */

    &:hover {
      color: #1890ff; /* Hover color for icons */
    }
  }

  .rc-tree-title {
    flex: 1;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;

    input {
      width: 100%;
      padding: 4px;
      font-size: 14px;
      border: 1px solid #ddd;
      border-radius: 4px;
    }

    span {
      display: inline-block;
      vertical-align: middle;

      &:hover {
        color: #1890ff;
      }
    }
  }

  .rc-tree-actions {
    display: flex;
    align-items: center;
    margin-left: auto;

    span {
      margin-left: 8px;
      cursor: pointer;
      transition: color 0.2s ease;

      &:hover {
        color: #1890ff;
      }
    }
  }
}
