.renderDateChangeBadges__container {
  margin-top: 4px;
  .badge {
    margin-left: 1px;
    font-size: 10px;
    margin-top: 3px;
  }
}

.SelectorComponent__container {
  display: flex;
  align-items: center;
  margin-top: 4px;
  font-size: 13px;
}
