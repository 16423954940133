.OpportunitiesShowPage__container {
  margin: 12px;
}

.OpportunitiesShowPage__header {
  padding: 8px;
  // border: 1px solid var(--bs-gray-500);
  width: 100%;
  // border-radius: 8px;
  // box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px,
  //   rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
}

.OpportunitiesShowPage__body {
  margin-top: 12px;
}

.OpportunitiesShowPage__bodySection {
}

.OpportunitiesShowPage__editableSection {
  display: inline-block;
  vertical-align: middle;

  .EditableInlineField__container {
    display: inline-block;
    margin-right: 4px;
    vertical-align: middle;
  }

  .separateLine {
    display: none;
  }
  &:not(:last-child) {
    .separateLine {
      display: inline-block;
      vertical-align: middle;
      margin-right: 4px;
      font-size: 14px;
    }
  }
}

.OpportunitiesShowPage__sellerHeader {
  font-size: 13px;
  min-height: 155px;

  &.moveToRight {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .CompanyAvatar__container {
    display: inline-block;
  }
}

.OpportunitiesShowPage__tableContainer {
  margin-top: 12px;
}

.OpportunitiesShowPage__bidderSelector {
  &::part(content) {
    min-width: 350px;
  }
}
