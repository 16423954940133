.BillLinesTable__container {
  .RTTableBodyRow__container {
    &.hasFlags {
      background-color: var(--ion-color-crimson-light);
      border-color: var(--ion-color-crimson);

      &:first-child {
        border-top-width: 1px;
      }
    }

    .RTTableBodyCell__container {
      &.dropdownSelector {
        padding: 0 0 0 1rem !important;
        cursor: pointer;

        .DropdownWithCustomChildren__container {
          &:hover {
            background-color: transparent !important;
          }
        }
      }
    }
  }

  .BillLinesTable__header {
    display: flex;
    align-items: center;
    padding: 0 12px;

    .btn {
      width: auto !important;
      min-height: 40px;
    }

    .flagButton {
      margin-right: 5px;
      font-size: 13px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}
