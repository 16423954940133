.AutoExtraForm__hierarchyForm {
  padding: 0;

  ion-col {
    padding: 0;
  }

  .ToggleSection__container {
    margin-top: 4px;
  }
}

.AutoExtraForm__qtyRangeContainer {
  font-size: 13px;
  .input {
    height: 29.5px;
    font-size: 13px;
  }
}
