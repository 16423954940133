.InvoiceEmailButton__overlay {
  .rc-tooltip-inner {
    background-color: white;
    color: black;
    min-width: 150px;
  }
}

.InvoiceEmailButton__emailItem {
  margin-top: 8px;
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 8px;
}
