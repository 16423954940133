.InvoiceActions_popup {
  background-color: transparent;
}

.InvoiceActions_approvalControls {
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
}

.InvoiceAction_rootPopup {
  padding: 10px;
  width: auto;
}

.InvoiceAction_otherButton {
  width: 97%;
}

.InvoiceAction__invoicePdf {
  .ViewPDF__root {
    max-height: unset;
    min-height: unset;
    height: 100%;

    .rpv-core__inner-pages {
      max-height: unset !important;
    }
  }
}

.InvoiceActions__dropdown {
  .dropdown-item {
    &:hover {
      background-color: transparent !important;
    }
  }
  .dropdown.btn-group {
    width: 100%;
  }
  button.ReusableButton__container {
    font-size: 14px;
    width: 100%;
    background-color: transparent !important;
    color: black;

    &.btn-primary {
      color: var(--bs-primary);
    }
    &.btn-secondary {
      color: var(--bs-secondary);
    }
    &.btn-danger {
      color: var(--bs-danger);
    }
    &.btn-success {
      color: var(--bs-success);
    }
    &.btn-warning {
      color: var(--bs-warning);
    }
    &.btn-info {
      color: var(--bs-info);
    }
  }
}
