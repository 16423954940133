.ReusableCardSelection__container {
}

.ReusableCardSelection__radioItem {
  border: 1px solid #e5e5e5;
  padding: 12px;
  border-radius: 12px;
  margin-bottom: 8px;
  cursor: pointer;

  // &:hover {
  //   opacity: 0.65;
  // }

  &.isSelected {
    border-color: var(--ion-color-concord);
  }
}
