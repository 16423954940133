.InvoiceBodyHeader__dropdown {
  // margin-right: 12px;
  // margin-left: 12px
}

.InvoiceBodyHeader__sellerCol {
  display: flex;
  align-items: center;
  font-size: 13px;
}

.InvoiceBodyHeader__sellerInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 14px;
  margin-left: 4px;
  font-weight: 600;
}

.InvoiceBodyHeader__item {
  &:not(:first-child) {
    margin-top: 8px;
  }
}

.InvoiceBodyHeader__dropdownRoot {
  display: flex;
  align-items: center;
}

.InvoiceBodyHeader__uploadSection {
  margin-left: 24px;

  .ImageView__body {
    box-shadow: none !important;
  }
}

.InvoiceBodyHeader__dropdownItem {
  display: flex;
  align-items: center;
}

.InvoiceBodyHeader__terminalDropdown {
  margin-left: 8px;
}

.InvoiceBodyHeader_header {
  // width: 100%;
  overflow-x: scroll;
  // margin-bottom: -20px;

  &::-webkit-scrollbar {
    display: none;
  }

  & {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

.InvoiceBodyHeader_header > table {
  min-width: 1300px;
  height: 100%;
}

.InvoiceBodyHeader_section {
  border-right: 1px solid #dee2e6;
}

.InvoiceBodyHeader__dateRangeLabel {
  font-size: 14px;
  font-weight: 600;
}

.InvoiceBodyHeader__dateRangeSelector {
  .DateRangeDropdownPicker__filterLabel {
    font-weight: 600;
    font-size: 14px;
    padding: 0 8px !important;
    border: none !important;
  }
}

.InvoiceBodyHeader__dateSelector {
  .ReusableDatePicker__dateInput {
    border-width: 0 !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    cursor: pointer;
    padding: 0 4px !important;
  }
}
