.InvoiceStatusChip__root {
}

.InvoiceStatusChip__statusOverlay {
  .rc-tooltip-arrow {
    right: -5px !important;
  }
}

.InvoiceStatusChip__status {
  margin: 0;
  color: var(--ion-color-slate);
  opacity: 1;
  transition: opacity 0.5s ease;

  .InvoiceStatusChip__dotStatus {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 4px;
  }

  &.--inactive {
    background: var(--ion-color-slate-contrast);

    .InvoiceStatusChip__dotStatus {
      background-color: var(--ion-color-slate-tint);
      box-shadow: 0 0 10px var(--ion-color-slate);
    }
  }

  &.--success {
    background: #d9ffd9;

    .InvoiceStatusChip__dotStatus {
      background-color: var(--ion-color-darkgreen-tint);
      box-shadow: 0 0 10px var(--ion-color-darkgreen);
    }
  }

  &.--failure {
    background: #ffd4e3;

    .InvoiceStatusChip__dotStatus {
      background-color: var(--ion-color-crimson-tint);
      box-shadow: 0 0 10px var(--ion-color-crimson);
    }
  }

  &.--process {
    background: #e7dbfc;

    .InvoiceStatusChip__dotStatus {
      background-color: #a58dd4;
      box-shadow: 0 0 10px var(--ion-color-purple);
    }
  }

  &.--brand {
    background: var(--ion-color-ice);

    .InvoiceStatusChip__dotStatus {
      background-color: var(--ion-color-concord-tint);
      box-shadow: 0 0 10px var(--ion-color-concord);
    }
  }

  &.--noCollection {
    background: #ffeca0;

    .InvoiceStatusChip__dotStatus {
      background-color: var(--ion-color-fleet);
      box-shadow: 0 0 10px var(--ion-color-fleet);
    }
  }

  &.--locked {
    background: var(--ion-color-fleet);
    color: white;

    .InvoiceStatusChip__dotStatus {
      background-color: #e9f3ff;
      box-shadow: 0 0 10px #ebf0f6;
    }
  }
}
