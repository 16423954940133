.AssignLoadsMap__mapContainer {
  .MapView__containerElement {
    height: 550px;
  }
}

.AssignLoadsMap__filterSection {
  display: inline-block;
  vertical-align: middle;
  .ConcordToolbar__root {
    display: inline-block;
    vertical-align: middle;
    width: auto;
  }
}
