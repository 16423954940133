.SellerRegexForm__highlightText {
  background: var(--highlight-text-background);
}

.SellerRegexForm__sellerRegexFormText {
  font-size: 14px;
}

.SellerRegexForm__regexInput {
  position: relative;

  .SellerRegexForm__badgeCountingMatchedText {
    position: absolute;
    top: -8px;
    right: -6px;
  }
}
