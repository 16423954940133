.MatchedBillLineRow__container {
  .RTPaper__container {
    box-shadow: none;
    border-radius: 0;
  }
  .BillLinesTable__header,
  .RTTableHead__container {
    display: none !important;
  }
}
