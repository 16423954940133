.CustomLdsQtyField__container {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;

  &:hover {
    border: 1px solid var(--ion-color-concord);
    background-color: rgb(255, 255, 255);
    color: black;
  }

  .numberInput {
    width: 65%;
    height: 100%;
    margin-right: 4px;
    font-size: 14px;
    border: none;
    outline: none;
    padding: 0 8px;
    background-color: transparent;
  }

  .formCheckbox {
  }
}
