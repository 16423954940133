.EditableInlineField__container {
  font-size: 13px;
}

.EditableInlineField__inputFieldContainer {
  display: inline-flex;
  align-items: center;

  input {
    max-width: 80px;
    border: 1px solid var(--bs-gray-500);
    border-radius: 4px;
    font-size: 13px;
  }

  .ReusableDatePicker__datePicker {
    display: inline-block;
    vertical-align: middle;
    width: auto !important;
    .ReusableDatePicker__dateInput {
      padding: 0 !important;
      font-size: 13px;
    }
  }
}

.EditableInlineField__label {
  font-weight: 600;
}
