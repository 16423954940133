.CostCodeOverlay__rootPopover {
  min-width: 380px;
}

.CostCodeOverlay__labelRoot {
  margin-right: 8px;

  ion-icon {
    margin-right: 4px;
    vertical-align: middle;
  }
}

.CostCodeOverlay__labelRoot,
.CostCodeOverlay__selector {
  display: inline-block;
}
